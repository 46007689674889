import Apertura from './Views/Apertura';
import Aniversario from './Views/Aniversario';
import Canales from './Views/Canales';
import Content from './Views/Content';
import ContentDetail from './Views/ContentDetail';
import ContentDetailPassingId from './Views/ContentDetailPassingId';
import Diaspora from './Views/Diaspora';
import Directoacuenta from './Views/Directoacuenta';
import Enrolamiento from './Views/Enrolamiento';
import EnviosFaciles from './Views/EnviosFaciles';
import Faqs from './Views/Faqs';
import Haiti from './Views/Haiti';
import Home from './Views/Home';
import Institucional from './Views/Institucional';
import Noticias from './Views/Noticias';
import Promo from './Views/Promo';
import SendingRD from './Views/SendingRD';
import Service from './Views/Service';
import Services from './Views/Services';
import Sucursales from './Views/Sucursales';
import SimpleRapido from './Views/SimpleRapido';
import Talentos from './Views/Talentos';
import Vimencash from './Views/Vimencash';
import VimencashOficinas from './Views/VimencashOficinas';
import Voye from './Views/Voye';
import {
    BrowserRouter,
    Switch,
    Route
    } from "react-router-dom"

import { Layout } from "./General"

import React from "react"

const Routing = ({ props }) => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/">
                <Layout {...props}>
                    <Home {...props} />
                </Layout>
            </Route>
            <Route exact path="/apertura">
                <Layout {...props}>
                    <Apertura {...props} />
                </Layout>
            </Route>
            <Route exact path="/enrolamiento">
                <Layout {...props}>
                    <Enrolamiento {...props} />
                </Layout>
            </Route>
            <Route exact path="/canales">
                <Layout {...props}>
                    <Canales {...props} />
                </Layout>
            </Route>
            <Route exact path="/nuevastarifas" render={() => (window.location = "https://v3.vimencawu.com.do/nuevastarifas")} />
            
            <Route exact path="/voyelajannanrd">
                <Layout {...props}>
                    <Voye {...props} />
                </Layout>
            </Route>
            <Route exact path="/simple-rapido">
                <Layout {...props}>
                    <SimpleRapido {...props} />
                </Layout>
            </Route>
            <Route exact path="/servicio/:alias/:id"
                   render={ propsd => <Layout {...props} {...propsd}>
                       <Service {...props} {...propsd}  />
                   </Layout>
                   } />

            <Route exact path="/directoacuenta" render={ (propsd) => <Layout {...props}>
                <Directoacuenta {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/haiti" render={ (propsd) => <Layout {...props}>
                <Haiti {...props} {...propsd}  />
            </Layout>} />

            {/* <Route exact path="/promo" render={ (propsd) => <Layout {...props}>
                <Promo {...props} {...propsd}  />
            </Layout>} /> */}

            <Route exact path="/promohaiti" render={ (propsd) => <Layout {...props}>
                <Haiti id = {34} {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/promousa" render={ (propsd) => <Layout {...props}>
                <ContentDetailPassingId id = {35} {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/enviosard" render={ (propsd) => <Layout {...props}>
                <EnviosFaciles {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/sendingtodr" render={ (propsd) => <Layout {...props}>
                <SendingRD {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/proteccion-al-usuario" render={ (propsd) => <Layout {...props}>
                <ContentDetail id={28} {...props} {...propsd}  />
            </Layout>} />

            <Route exact path="/haiti/es" render={ (propsd) => <Layout {...props}><Haiti lang={1} {...props}  />
            </Layout>} />

            <Route exact path="/haiti/cr" render={ (propsd) => <Layout {...props}>
                <Haiti lang={2}{...props}  />
            </Layout>} />

            <Route exact path="/servicio/remesasadomicilio" render={ (propsd) => <Layout {...props}>
                <Service id={10} {...props} {...propsd}  />
            </Layout>
            } />

            <Route exact path="/remesasadomicilio" render={ (propsd) => <Layout {...props}>
                <Service id={10} {...props} {...propsd}  />
            </Layout>
            } />

            <Route exact path="/remesa-a-cuenta" render={ (propsd) => <Layout {...props}>
                <Service id={5} {...props} {...propsd}  />
            </Layout>
            } />

            <Route exact path="/:alias/:id" render={ (propsd) => <Layout {...props} {...propsd}>
                <Content {...propsd}  {...props}  />
            </Layout>
            }  />
            <Route exact path="/noticias">
                <Layout {...props}>
                    <Noticias {...props} />
                </Layout>
            </Route>

            <Route exact path="/oficinas">
                <Layout {...props}>
                    <Sucursales {...props}/>
                </Layout>
            </Route>

            <Route exact path="/oficinas-vimencash">
                <Layout {...props}>
                    <VimencashOficinas {...props} />
                </Layout>
            </Route>

            <Route exact path="/vimencash">
                <Vimencash {...props} />
            </Route>

            <Route exact path="/faqs">
                <Layout {...props}>
                    <Faqs {...props} />
                </Layout>
            </Route>

            <Route exact path="/servicios">
                <Layout {...props}>
                    <Services {...props}/>
                </Layout>
            </Route>

            <Route exact path="/plazadeladiasporadominicana">
                <Diaspora {...props}/>
            </Route>

            <Route exact path="/talentos">
                <Layout {...props}>
                    <Talentos  {...props} />
                </Layout>
            </Route>

            <Route exact path="/institucional">
                <Layout {...props}>
                    <Institucional  {...props} />
                </Layout>
            </Route>

            <Route exact path="/promo">
                <Layout {...props}>
                    <Promo  {...props} />
                </Layout>
            </Route>
            <Route exact path="/35anos">
                <Layout {...props}>
                    <Aniversario  {...props} />
                </Layout>
            </Route>

            

            
        </Switch>
    </BrowserRouter>
)

export default Routing;